import React, {useEffect} from "react";

import {
  courses, passingGrade, coreSeqNum,
  advancedCoreSeqNum,
  operationsResearch,
  statsSeqNum,
  Practicum,
  ATrackSeqNum,
  BTrackSeqNum,
  CTrackSeqNum
} from "./omsa";
import { calcGPA } from "./utilities";

function isPassingGrade(grade) {
  return passingGrade.includes(grade);
}

function hasCoreRequirements(db) {
  return uniqueCoursesTakenAndPassed(db, coreSeqNum).length === 3
}

function hasAdvancedCoreRequirements(db) {
  return uniqueCoursesTakenAndPassed(db, advancedCoreSeqNum).length === 2;
}

function hasOperationsResearchRequirement(db) {
  return uniqueCoursesTakenAndPassed(db, operationsResearch).length >= 1;
}

function hasStatisticsRequirement(db) {
  return uniqueCoursesTakenAndPassed(db, statsSeqNum).length >= 2;
}

function hasPracticumRequirement(db) {
  return uniqueCoursesTakenAndPassed(db, Practicum).length >= 1;
}

function hasATrack(db) {
  const atrackCourses = uniqueCoursesTakenAndPassed(db, ATrackSeqNum)
  const statsCourses = uniqueCoursesTakenAndPassed(db, statsSeqNum)
  const ORCourses = uniqueCoursesTakenAndPassed(db, operationsResearch);

  const allUniqueCourses = [
    ...new Set([...statsCourses, ...atrackCourses, ...ORCourses])
  ];

  return allUniqueCourses.length >= 5;
}

function hasBTrack(db) {
  return uniqueCoursesTakenAndPassed(db, BTrackSeqNum).length >= 2;
}

function hasCTrack(db) {
  const trackElectives = uniqueCoursesTakenAndPassed(db, CTrackSeqNum)
  const statsCourses = uniqueCoursesTakenAndPassed(db, statsSeqNum)
  const machineLearning = uniqueCoursesTakenAndPassed(db, [13]);
  const allUniqueCourses = [
    ...new Set([...statsCourses, ...trackElectives, ...machineLearning])
  ];




  return (
   machineLearning.length >= 1 && ( trackElectives.length >=3 ||  (trackElectives.length >=2 && statsCourses.length >=3))
  );
}

function uniqueCoursesTakenAndPassed(db, listOfSeq) {
  let CoursesTakenAndPassed = db.filter(
    (x) =>
      listOfSeq.includes(x.courseSeqNum) &&
      (isPassingGrade(x.grade) || x.credit || x.audit)
  ).map(x => x.courseSeqNum);
  const uniqueCoursesTakenAndPassed = [...new Set(CoursesTakenAndPassed)];
  return uniqueCoursesTakenAndPassed
}

function totalCredits(db) {
  let coursesTaken = db
    .filter((x) => isPassingGrade(x.grade) || x.credit)
    .map((x) => x.courseSeqNum);
  let uniqueCoursesTaken = [...new Set(coursesTaken)];
  uniqueCoursesTaken = courses.filter((x) => coursesTaken.includes(x.SeqNum));
  let uniqueCoursesTakenCR = uniqueCoursesTaken.map((x) => x.CR);
  let totalCredits = uniqueCoursesTakenCR.reduce((a, b) => a + b, 0);
  return totalCredits;
}

function hasCompletedInSixYears(db) {
  const years = db.map((x) => x.year).filter((x) => x);
  return Math.max(...years) - Math.min(...years) + 1 <= 6;
}

export function DegreeVerification({ db, tracks, setResult, className }) {



  const trackA = tracks.includes("A");
  const trackB = tracks.includes("B");
  const trackC = tracks.includes("C");
  
  const GPA = calcGPA(db);
  const metGPA = GPA >= 2.7
  const metTotalCredit = totalCredits(db) >= 36
  const hasCore = hasCoreRequirements(db);
  const hasAdvCore = hasAdvancedCoreRequirements(db);
  const hasStat = hasStatisticsRequirement(db);
  const hasOR = hasOperationsResearchRequirement(db);


  const hasATrackFinished = trackA && hasATrack(db);
  const hasBTrackFinished = trackB && hasBTrack(db);
  const hasCTrackFinished = trackC && hasCTrack(db);

  

  const hasTrack = (!trackA || hasATrackFinished ) && (!trackB || hasBTrackFinished ) &&  (!trackC || hasCTrackFinished ) 
  const hasPract = hasPracticumRequirement(db);
 
  const meetsAll = metGPA && metTotalCredit && metTotalCredit && hasCore && hasAdvCore && hasStat &&  hasOR && hasTrack && hasPract

  useEffect(() => {
      setResult(meetsAll)
  }, [db, tracks, meetsAll, setResult])

  return (
    <div className={"heading requirement-container " + className} >
      <h2>Degree requirements </h2>
      
      <div className="requirement-holder">
      
        <ul className="requirement-list">
          <a href="http://tinyurl.com/gt-omsa">See http://tinyurl.com/gt-omsa for curriculum and requriements</a>
          <li> {metGPA ? "✅" : "❌"}2.7 GPA Requirement ({GPA})          </li>
          <li> {metTotalCredit ? "✅" : "❌"}36 Credit Hour Requirement ({totalCredits(db)}):{" "} </li>
          <li> {hasCore ? "✅" : "❌"}Core Requirements</li>
          <li> {hasAdvCore ? "✅" : "❌"}Advanced Core Requirements          </li>
          <li> {hasStat ? "✅" : "❌"}Statistics Requirement          </li>
          <li> {hasOR ? "✅" : "❌"}Operation Research Requirement{" "}</li>  
          {trackA ? (<li>{hasATrackFinished ? "✅" : "❌"}Analytics Track Requirement </li>) : null}
          {trackB ? (<li>{hasBTrackFinished ? "✅" : "❌"}Business Track Requirement</li>) : null}
          {trackC ? (<li>{hasCTrackFinished ? "✅" : "❌"}Computation Track Requirement</li>) : null}
          <li>{ hasPract? "✅" : "❌"}Practicum Requirement          </li>
          <li style={{ display: "none" }}> 
            {hasCompletedInSixYears(db) ? "✅" : "❌"}Completed in 6 years
            Requirement{" "}
          </li>
        </ul>
      </div>
    </div>
  );
}
