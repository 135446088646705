import React, { useState } from "react";
import { grades } from "./utilities";

export function CourseCard({ course, changeGrade, options }) {
  function onGradeSelect(e) {
    changeGrade(e.target.value);

    setIsGradeChange(!isGradeChange);
  }

  function gradeClick() {
    setIsGradeChange(!isGradeChange);
  }

  const Selector = (
    <div className="grade">
      <select onChange={onGradeSelect}>
        {grades.map((grade) => (
          <option key={grade} value={grade} selected={grade === course.grade}>
            {grade}
          </option>
        ))}
      </select>
    </div>
  );

  let [isGradeChange, setIsGradeChange] = useState(false);

  const style = { textAlign: "center", color: "white" };

  return (
    <div style={style}>
      <span style={{ display: "flex", justifyContent: "space-evenly" }}>
        {options?.showFullname && (
          <div className="fullName">{course.FullName}</div>
        )}
        {options?.showNick && <div className="fullName">{course.Nick}</div>}
      </span>
      {options?.showDescription && (
        <div style={{ color: "white" }} className="description">
          {course.Description}
        </div>
      )}

      {options?.showGrade &&
        (isGradeChange ? (
          Selector
        ) : (
          <div
            className="grade"
            style={{ color: "white" }}
            onClick={gradeClick}
          >
            {course.grade}
          </div>
        ))}

      {false && options?.showOMSHub && (
        <a
          className="description"
          target="_blank"
          rel="noopener noreferrer"
          href={
            "https://omshub.org/course/" +
            course.FullName.split(" ").join("-")
          }
          style={style}
        >
          OMS Hub
        </a>
      )}

      <div>{/*course.courseSeqNum*/}</div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "2fr 2fr",
          textAlign: "left",
          fontSize: "1rem",
        }}
      >
        {false && options?.showWorkload && <div>Workload</div>}
        {false && options?.showWorkload && <div>{course.Workload?.toFixed(1)}</div>}

        {false && options?.showDifficulty && <div>Difficulty</div>}
        {false && options?.showDifficulty && (
          <div title={course.Difficulty} style={{ justifySelf: "left" }}>
            {" "}
            /*<Rating
              name="Difficulty"
              readOnly={true}
              max={5}
              value={course.Difficulty}
              starColor={"white"}
              precision=".1"
              size="small"
            />{" "}*/
          </div>
        )}

        {false && options?.showRating && <div>Rating</div>}
        {false && options?.showRating && (
          <div title={course.Rating} style={{ justifySelf: "left" }}>
            <Rating
              name="Rating"
              readOnly={true}
              max={5}
              value={course.Rating}
              precision=".1"
              size="small"
            />
          </div>
        )}
      </div>
    </div>
  );
}
