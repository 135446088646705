import React from "react";
import {summerSeqNum } from "./omsa";

export function SummerReq({ schedule, masterCourseList }) {

//TODO turn this into a set, to prevent duplicates, then turn to an array
debugger
var cousesInSummer = schedule.filter(x => x.semester === "Summer" && x.courseID !== 1).map(x => x.courseSeqNum)

let summerWarnings = []

cousesInSummer.forEach(courseInSummer => {
    if(!summerSeqNum.includes(courseInSummer)){
        summerWarnings.push(courseInSummer);
    }
    
});

function getCourseObjectbyID(courseID){
    return masterCourseList.find(x => x.SeqNum === courseID);
}

const hasZeroWarnings = summerWarnings.length === 0;
  return (
      <div>
            <h3 style={{"textAlign": "start", "fontWeight" : "unset",  "margin":".1em", marginLeft:"1em"}}> 
            {`${hasZeroWarnings ? "✅":"❌"}Summer validation `}  
            </h3>
            {hasZeroWarnings ? null :  <ul style={{marginLeft:"2em"}} >
                {summerWarnings.map(summerCourse =>  
                <>
                    <li  style={{"whiteSpace": "nowrap"}}>
                        {`${getCourseObjectbyID(summerCourse).FullName} is not offered in the summer.` } 
                    </li>
                </>
                )}
            </ul> }
    </div>
  );
}
