import React from "react";
import { Semester } from "./Semester";
import { semester2year } from "./utilities";

export function Year({ year, className, options }) {
  let yearRange;
  if (typeof year.year === "string" && year.year.indexOf("-") >= 0) {
    yearRange = year.year.split("-").map((x) => x.trim());
  } else {
    yearRange = [year.year, year.year];
  }

  return (
    <li className={"year " + className}>
      <div className="yearNumber">{year.year}</div>
      <div className="semester-container">
        {year.semesters.map((semester) => (
          <Semester
            key={semester.semester}
            semester={semester}
            year={
              yearRange[
                semester2year.find((x) => x.name === semester.semester).value
              ]
            }
            options = {options}
          />
        ))}
      </div>
    </li>
  );
}
