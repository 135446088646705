import React from "react";

export function DeleteConfirm({cancel, ok}) {
    return (
        <div   style={{padding:"1em"}}>
            <h4>Are you sure you want to DELETE your entire schedule?</h4>
            <div style={{display:"flex", flexDirection:"row",  justifyContent: "flex-end",  width: "100%"}}>
                <button style={{margin: ".5rem"}} onClick={cancel}>cancel</button>
                <button style={{margin: ".5rem"}} onClick={ok}>Yes</button>
            </div>
        </div>
    );
}
