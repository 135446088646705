import React from "react";

export function TrackPicker({
  track,
  onChangeTrack,
  title,
  tracks,
  atLeastOne = false
}) {
  function changehandler(e) {
    let tempTrack = [...track];
    if (e.target.checked) {
      tempTrack.push(e.target.value);
    } else {
      tempTrack.splice(track.indexOf(e.target.value), 1);
    }
    if (!atLeastOne || tempTrack.length > 0) {
      onChangeTrack(tempTrack);
    }
  }

  return (
    <div className="tracks-container">
      <div>
        <div className="title">{title}</div>
        <ul className="tracks">
          {tracks.map((x) => (
            <li key={x.value}>
              <input
                type="checkbox"
                id={x.value}
                value={x.value}
                checked={track.indexOf(x.value) >= 0}
                onChange={changehandler}
              ></input>
              <label>{x.name}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
