import React from "react";
import {Row} from "./Row";

export function Faq() {
    return (
        <div style={{ padding:"3em", overflow:"auto", maxHeight:"75vh", textAlign:"left"} } >
            <h1>FAQ</h1>
            <ul>
                <Row header="What is the OMSA degree Planner?">It helps you plan out the courses you want to take to complete the OMSA degree</Row>
                <Row header="How do I add a course to my schedule">drag from the list of courses on the left, and drop into the schedule</Row>
                <Row header="How do I remove a course to my schedule">drag the course from your schedule to outside the schdule and drop</Row>
                <Row header="How do I change my grade in a course">Click on the current letter grade, this brings up a dropdown where you can select a new grade</Row>
                <Row header="How do I pick a track?">Select the track from tracks checkboxes, to change a track you must select a new one and deselect an old one to remove it.</Row>
                <Row header="Can I pick more than one track? ">Yes, just select the tracks you wish, however the degree verification may not work for this.</Row>
                <Row header="How do I get credit for taking a course via EDX?">Select the appropiate checkbox under EDX Credit</Row>
                <Row header="How do I mark that I have Opted out of a course?">Select the appropiate checkbox under Opt Out</Row>
                <Row header="What is the difference between Credit and Opted out">Credit counts for credit hours, whereas Opted out does not.  Neither count for GPA</Row>
                <Row header="How do I export a schedule">Click the export icon and copy the text in the popup</Row>
                <Row header="How do I import a schedule">click  the import icon and paste the text that was saved from a prior export</Row>
                <Row header="How do I take a screenshot">Click the screenshot camera button, right click the image the appears, and click "Copy image"</Row>
                <Row header="How do I clear my scheudle">Click the delete all trash button, click the "yes" button</Row>
                <Row header="How do I know what the degree requirements are?">OMSA.GA of course!</Row>
                <Row header="How do I filter courses">Under OMSA classes, you can filter by name, abbreciation, number, department, past semesters, degree requirements and filter out already passed courses.</Row>
                <Row header="Why can I add a course in the summer, when the course is not available in the summer ">Courses may be offered in the summer in the future, so I don’t want to block this action entirely.  An upcoming feature would be a validation check to offer soft warnings on non-summer courses, and soft prereqs.</Row>
                <Row header="How do I report bugs?">email me at sball (thirty-five) AT Gatech.edu or message me on slack</Row>
                <Row header="How do I request a feature">email me at sball (thirty-five) AT Gatech.edu or message me on slack</Row>
                <Row header="What technologies are used to make this tool? ">React</Row>
                <Row header="What do the colors of the courses mean?">They are based on the department.</Row>
                <Row header="When will I be able to do xyz with this tool?">Soon I hope, planning to work on this throughout the summer.</Row>
                    
            </ul>
        </div>
    );
}
