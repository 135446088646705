import React, { useState, useReducer } from "react";
import "./styles.css";
import { convertToJSON } from "./databaseToJsonConverter";
import { DegreeVerification } from "./DegreeVerification";
import { DragDropContext } from "react-beautiful-dnd";
import { CourseMasterList } from "./CourseMasterList";
import { Year } from "./Year";
import { TrackPicker } from "./TrackPicker";
import { reducer, scheduleDispatchContext } from "./scheduleReducer";
import {
  reducer as settingsReducer,
  settingsDispatchContext,
} from "./settingsReducer";
import { tracks, edx, audit } from "./utilities";
import { OutsideCoursePicker } from "./OutsideCoursePicker";
//import { ConfigurationPanel } from "./ConfigurationPanel";
//import Modal from "@material-ui/core/Modal";

import {Modal} from "@mui/material"

import html2canvas from "html2canvas";
import { Importer } from "./Importer";
import { Exporter } from "./exporter";
import { Faq } from "./Faq";
import { DeleteConfirm } from "./DeleteConfirm";
import { News } from "./News";
import { Settings } from "./Settings";
import { Prereq } from "./Prereq";
import { SummerReq } from "./SummerReq";

import Grade from "./Grade.tsx"

import { courses } from "./omsa";

export default function App({ db_init, settings_init }) {
  const [additionalConfigExpanded, setadditionalConfigExpanded] =
    useState(true);
  const [suggestionExpanded, setsuggestionExpanded] = useState(true);

  const [yearView, setyearView] = useState("Academic");
  const [open, setOpen] = useState(false);

  const [db, dispatch] = useReducer(reducer, db_init);
  const [settings, dispatchSettings] = useReducer(
    settingsReducer,
    settings_init
  );

  const [track, setnewTrack] = useState(["C"]);

  const [hasAllReq, sethasAllReq] = useState(false);

  const [openModalMode, setopenModalMode] = useState("");

  const schedule = convertToJSON(db, yearView);
  const edxCredit = db.filter((x) => x.credit).map((x) => x.courseSeqNum);
  const auditStatus = db.filter((x) => x.audit).map((x) => x.courseSeqNum);

  const ismobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  window.onscroll = function () {
    if (ismobile) {
      document.body.scroll = "no";
      document.body.style.overflow = "hidden";
      document.height = window.innerHeight;
      //window.scroll(0, 0);
    }
  };

  function handleOnDragEnd(result) {
    if (
      result.destination === null &&
      result.source.droppableId !== "MasterList"
    ) {
      let scheduleSeqNum = parseInt(result.draggableId, 10);
      dispatch({ type: "delete", payload: { SeqNum: scheduleSeqNum } });
    }

    if (result.reason === "DROP" && result.destination) {
      if (result.source.droppableId === "MasterList") {
        //adding a new course to the db
        let [semester, year] = result.destination.droppableId.split(":");

        dispatch({
          type: "add",
          payload: {
            year: year,
            semester: semester,
            courseSeqNum: parseInt(result.draggableId, 10),
            grade: "A",
            credit: false,
            audit: false,
          },
        });
      } else {
        let scheduleSeqNum = parseInt(result.draggableId, 10);
        let [newSemester, newYear] = result.destination.droppableId.split(":");
        dispatch({
          type: "move",
          payload: {
            SeqNum: scheduleSeqNum,
            newYear: newYear,
            newSemester: newSemester,
          },
        });
      }
    }
  }

  function toggleScheduleView(e) {
    const views = ["Academic", "Calendar"];
    const currentViewIndex = views.indexOf(yearView);

    setyearView(views[(currentViewIndex + 1) % views.length]);
  }

  function handleDragUpdate(e) {}

  function hideAdditionalContent(e) {
    setadditionalConfigExpanded(!additionalConfigExpanded);
  }

  function updateOutsideCourse(e, isCredit, isAudit) {
    const courseSeqNum = parseInt(e.target.value, 10);
    if (e.target.checked) {
      dispatch({
        type: "add",
        payload: {
          year: NaN,
          semester: NaN,
          courseSeqNum,
          grade: NaN,
          credit: isCredit,
          audit: isAudit,
        },
      });
    } else {
      const seqNum = db.find(
        (x) =>
          ((isCredit && x.credit) || (isAudit && x.audit)) &&
          x.courseSeqNum === courseSeqNum
      ).SeqNum;
      dispatch({ type: "delete", payload: { SeqNum: seqNum } });
    }
  }

  function deleteAll() {
    setOpen(true);
    setopenModalMode("deleteAll");
  }

  function exportData() {
    setOpen(true);
    setopenModalMode("export");
  }

  function importData() {
    setOpen(true);
    setopenModalMode("import");
  }

  function screenshot() {
    const tempadditionalConfigExpanded = additionalConfigExpanded;
    setadditionalConfigExpanded(true);
    setOpen(true);
    setopenModalMode("screenshot");

    setTimeout(
      () => {
        document
          .querySelectorAll(".hide-screenshot")
          .forEach((element) => element.classList.add("hide"));
        document.querySelector(".left-side").style.maxWidth = "33vw";

        setTimeout(() => {
          html2canvas(document.querySelector(".left-side")).then(function (
            canvas
          ) {
            var img = document.createElement("img");
            img.src = canvas.toDataURL("image/png");

            var div = document.createElement("div");
            div.contentEditable = true;
            var textDescription = document.createElement("div");
            textDescription.innerText = 'Right click and select "Copy image"';
            textDescription.className = "screenshot-header";
            textDescription.style.background = "white";
            div.appendChild(textDescription);
            div.appendChild(img);

            document.querySelector(".screenshot-holder").appendChild(div);

            // do copy
            //SelectText(div);
            document.execCommand("Copy");
            // document.body.removeChild(div);
          });

          document.querySelector(".left-side").style.maxWidth = "";
          document
            .querySelectorAll(".hide-screenshot")
            .forEach((element) => element.classList.remove("hide"));
          setadditionalConfigExpanded(tempadditionalConfigExpanded);
        }, 350);
      },
      tempadditionalConfigExpanded ? 0 : 0
    );
  }

  return (
    <scheduleDispatchContext.Provider value={dispatch}>
      <settingsDispatchContext.Provider value={dispatchSettings}>
        <div className="App ">
          <div className="ads hide-screenshot">
           
             </div>
          <div className="content">
            <div className="header">
              <i
                className="fa fa-gear"
                style={{ fontSize: "2rem", color: "transparent" }}
              ></i>
              <h1>OMSA degree planner</h1>
              <div className="button-holder">
                {!ismobile && (
                  <button
                    className="gear-button"
                    aria-label="take screenshot"
                    onClick={screenshot}
                    //onClick={() => setOpen(true)}
                  >
                    <i
                      title="take screenshot"
                      className="fa fa-camera"
                      style={{
                        fontSize: "2rem",
                        color: "lightGray",
                      }}
                    ></i>
                  </button>
                )}
                <Modal
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  className="modal"
                >
                  <div style={{ backgroundColor: "rgb(23 48 74)" }} class="App">
                    {openModalMode === "import" && (
                      <Importer
                        cancel={() => {
                          setOpen(false);
                          setopenModalMode("");
                        }}
                        submit={(importData) => {
                          dispatch({ type: "import", payload: importData });
                          setOpen(false);
                          setopenModalMode("");
                        }}
                      ></Importer>
                    )}
                    {openModalMode === "export" && (
                      <Exporter
                        cancel={() => {
                          setOpen(false);
                          setopenModalMode("");
                        }}
                        db={db}
                      ></Exporter>
                    )}
                    {openModalMode === "screenshot" && (
                      <div className="screenshot-holder"></div>
                    )}
                    {openModalMode === "news" && <News></News>}
                    {openModalMode === "faq" && <Faq></Faq>}
                    {openModalMode === "deleteAll" && (
                      <DeleteConfirm
                        cancel={() => {
                          setOpen(false);
                        }}
                        ok={() => {
                          dispatch({ type: "deleteAll" });
                          setOpen(false);
                        }}
                      ></DeleteConfirm>
                    )}
                    {openModalMode === "settings" && (
                      <Settings settings={settings}></Settings>
                    )}
                  </div>
                </Modal>
                <button
                  className="gear-button"
                  aria-label="export"
                  onClick={exportData}
                  //onClick={() => setOpen(true)}
                >
                  <i
                    title="export"
                    className="fa fa-download"
                    style={{
                      fontSize: "2rem",
                      color: "lightGray",
                    }}
                  ></i>
                </button>

                <button
                  className="gear-button"
                  aria-label="import"
                  onClick={importData}
                  //onClick={() => setOpen(true)}
                >
                  <i
                    title="import"
                    className="fa fa-upload"
                    style={{
                      fontSize: "2rem",
                      color: "lightGray",
                    }}
                  ></i>
                </button>

                <button
                  className="gear-button"
                  aria-label="delete all courses"
                  onClick={deleteAll}
                  //onClick={() => setOpen(true)}
                >
                  <i
                    title="delete all courses"
                    className="fa fa-trash"
                    style={{
                      fontSize: "2rem",
                      color: "lightGray",
                    }}
                  ></i>
                </button>

                <button
                  aria-label="settings"
                  className="gear-button"
                  onClick={() => {
                    setOpen(true);
                    setopenModalMode("settings");
                  }}
                >
                  <i
                    title="settings"
                    className="fa fa-gear"
                    style={{ fontSize: "2rem", color: "lightGray" }}
                  ></i>
                </button>

                <button
                  className="gear-button"
                  aria-label="What's New"
                  onClick={() => {
                    setOpen(true);
                    setopenModalMode("news");
                  }}
                >
                  <i
                    title="what's new?"
                    className="fa fa-newspaper-o"
                    style={{
                      fontSize: "2rem",
                      color: "lightGray",
                    }}
                  ></i>
                </button>
                <button
                  className="gear-button"
                  aria-label="FAQ"
                  onClick={() => {
                    setOpen(true);
                    setopenModalMode("faq");
                  }}
                >
                  <i
                    title="Fequently asked questions"
                    className="fa fa-question"
                    style={{
                      fontSize: "2rem",
                      color: "lightGray",
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <DragDropContext
              onDragEnd={handleOnDragEnd}
              onDragUpdate={handleDragUpdate}
            >
              <div className="course-container">
                <div className="left-side ">
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <DegreeVerification
                      className="hide-screenshot"
                      db={db}
                      tracks={track}
                      credit={edxCredit}
                      audit={auditStatus}
                      setResult={sethasAllReq}
                    />

                    <div>
                      <div className="heading hide-screenshot">
                        <h2>Suggestions</h2>
                        <button
                          style={{ verticalAlign: "text-bottom" }}
                          onClick={(e) =>
                            setsuggestionExpanded(!suggestionExpanded)
                          }
                        >
                          {!suggestionExpanded ? "+" : "-"}
                        </button>
                      </div>
                      <div
                        className={
                          "hide-screenshot suggestion-config " +
                          (suggestionExpanded ? "show" : "hide")
                        }
                        style={{ display: "block" }}
                      >
                        <div>
                          <Prereq
                            schedule={db}
                            masterCourseList={courses}
                          ></Prereq>
                          <SummerReq
                            schedule={db}
                            masterCourseList={courses}
                          ></SummerReq>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={{"display": "flex",     "margin": "10px", "flexDirection":ismobile? "column" : "row-reverse"  }}>
                    <div style={{"width":ismobile? "auto" : "33vw"}} >
                      <div className="heading hide-screenshot">
                        <h2>Tracks, credits, and opt outs</h2>
                        <button
                          style={{ verticalAlign: "text-bottom" }}
                          onClick={hideAdditionalContent}
                        >
                          {!additionalConfigExpanded ? "+" : "-"}
                        </button>
                      </div>
                      <div
                        className={
                          "additional-config " +
                          (additionalConfigExpanded ? "show" : "hide")
                        }
                        style={{ justifyContent: "center" }}
                      >
                        <TrackPicker
                          track={track}
                          tracks={tracks}
                          onChangeTrack={setnewTrack}
                          title="Tracks"
                          atLeastOne={true}
                        />
                        <OutsideCoursePicker
                          track={edxCredit}
                          tracks={edx}
                          onChangeTrack={(e) =>
                            updateOutsideCourse(e, true, false)
                          }
                          title="EDX credit"
                        />
                        <OutsideCoursePicker
                          track={auditStatus}
                          tracks={audit}
                          onChangeTrack={(e) =>
                            updateOutsideCourse(e, false, true)
                          }
                          title="Opt out"
                        />
                      </div>

                      <div className="heading">
                        <h2>
                          {hasAllReq ? "🎓" : null} Schedule ({yearView} View){" "}
                          <button
                            style={{ verticalAlign: "text-bottom" }}
                            className="hide-screenshot"
                            onClick={toggleScheduleView}
                          >
                            toggle view
                          </button>
                        </h2>
                        <ul className="schedule">
                          {schedule.map((year, index) => (
                            <Year
                              className={
                                index === 0 || index === schedule.length - 1
                                  ? "hide-screenshot"
                                  : ""
                              }
                              key={year.year}
                              year={year}
                              options={settings}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>

                    <CourseMasterList style={{"width":ismobile? "auto" : "40vw"}}
                      className="hide-screenshot"
                      db={db}
                      settings={settings}
                    />
                  </div>
                </div>

                <div className="right-side"></div>
              </div>
            </DragDropContext>
          </div>

          <div className="ads hide-screenshot"></div>
        </div>
      </settingsDispatchContext.Provider>
    </scheduleDispatchContext.Provider>
  );
}
