import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import { grades } from "./utilities";
import styled from "styled-components";

function Grade({ grade, setGrade }) {
  const [isUpdatingGrade, setisUpdatingGrade] = useState(false);

  const ReadOnlyGrade = styled.div`
    color: white;
  `;

  return (
    <>
      {isUpdatingGrade ? (
        <select
          autoFocus={true}
          tabIndex={0}
          onChange={(e) => {
            setisUpdatingGrade(false);
            setGrade(e.target.value);
          }}
          title="Change grade"
        >
          {grades.map((gradeOption) => (
            <option
              key={gradeOption}
              value={gradeOption}
              selected={gradeOption === grade}
            >
              {gradeOption}
            </option>
          ))}
        </select>
      ) : (
        <ReadOnlyGrade
          tabIndex={0}
          className="grade"
          onClick={() => setisUpdatingGrade(true)}
          onKeyUp={(e) => {
            console.log(e);
            if (e.key === "Enter") {
              setisUpdatingGrade(true);
            }
          }}
        >
          {grade}
        </ReadOnlyGrade>
      )}
    </>
  );
}

export default Grade;
