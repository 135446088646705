import React from "react";
import lz from "lz-string";

export function Importer({submit, cancel}) {

  const converter = {
    "a" : "audit",
    "csn" : "courseSeqNum",
    "c" : "credit",
    "g" : "grade",
    "s" : "semester",
    "n" : "SeqNum",
    "y" : "year",
  }

  const season = {
     "F" : "Fall"   ,
     "Sp" : "Spring" ,
     "Su" : "Summer" ,
  }

  const converterValue = {
    "audit": (value) => value ===1  ? true : false,
    "courseSeqNum": (value) => value ,
    "credit":(value) => value ===1  ? true : false,
    "grade":(value) => value ,
    "semester":(value) => season[value],
    "SeqNum":(value) => value + 7000,
    "year":(value) => value ==null ? null :  (value + 2016).toString()
  }

function convertKeys(dbrow){
  let bigRow = {...dbrow}

  bigRow = Object.keys(bigRow).reduce( (destination, current) => {destination[converter[current]] = bigRow[current]; return destination; }, {})
  

  Object.keys(bigRow).forEach(key => {
    bigRow[key]= converterValue[key](bigRow[key]);
  });


 

  return bigRow;



}

    

  function submitFunc (){
    try {
      const rawData = document.querySelector("#importData").value
      const decompressString = lz.decompressFromEncodedURIComponent(rawData);
      const decompressJSON = JSON.parse(decompressString);
  
      const destination = []
      decompressJSON.forEach(dbRow => {
        destination.push(convertKeys(dbRow)) 
      });
  
      submit(destination)
    } catch (error) {
      cancel()
    }
   
  }



  return (
    <div style={{display:"flex", flexDirection:"column", alignItems: "center", padding: "1rem"}} >
        <div>Paste in an exported degree plan string</div>
        
        <textarea style={{margin:".5rem"}} id="importData"  rows="8" cols="100"></textarea>

        <div style={{display:"flex", flexDirection:"row",  justifyContent: "flex-end",  width: "100%"}}>
          <button style={{margin: ".5rem"}} onClick={cancel}>cancel</button>
          <button style={{margin: ".5rem"}} onClick={submitFunc}>submit</button>
        </div>
    </div>



  );
}
