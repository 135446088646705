import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { getDB } from "./databaseToJsonConverter";
import { defaultSettings } from "./Settings";


const rootElement = document.getElementById("root");
const initDB = JSON.parse(localStorage.getItem("db")) ?? getDB();
const initSettings = JSON.parse(localStorage.getItem("settings")) ?? defaultSettings;

if(!initSettings.semester){
  initSettings.semester = defaultSettings.semester;
}

ReactDOM.render(
  <React.StrictMode>
    <App db_init={initDB}
       settings_init= {initSettings} />
  </React.StrictMode>,
  rootElement
);
