import React from "react";
import {Row} from "./Row";


export function News() {
    return (
        <div  style={{padding:"3em", textAlign:"left"}}>
            <h1>V 1.3 Whats new?</h1>
            <ul>
                <Row header="New layout">changed the layout to be more mobile friendly</Row>
                <Row header="Suggestions">Added prereqs and summer suggestions</Row>
                <Row header="Spring 2022 courses">Added Spring 2022 courses </Row>
            </ul>

            <h1>V 1.2 Whats new?</h1>
            <ul>
                <Row header="Customize course visuals">Added the ability customize which fields are displayed on courses</Row>
                <Row header="New course fields">Added a link to OMSCentral, course Difficulty, Workload, and Rating</Row>
                <Row header="Sorting">Courses can now be sorted by Difficulty, Workload, and Rating</Row >
            </ul>

            <h1>V 1.1 Whats new?</h1>
            <ul>
                <Row header="Screenshots">Added the ability to take screenshots via screenshot button, does not work well on mobile and is not supported in mobile at this time</Row>
                <Row header="Import and Export">Added the ability import and export schedules</Row>
                <Row header="Help faq">Added an faq section until more extensive documentation is done.</Row >
                <Row header="What's new">Added this what's new section to see what changes have been made.</Row >
                <Row header="Mobile improvments">Zoomed out the viewport to better handle differentiating drag and drop versus panning.</Row>
                <Row header="Spelling fixes and title updates">Fixed some embarassing typos</Row>
                <Row header="More past semesters to filter by">Courses can now be filtered by past semesters back until 2019, more to come later</Row>
                <Row header="Link to OMSA.GA">Added link to the greatest source of OMSA info</Row >
            </ul>
        </div>
    );
}
