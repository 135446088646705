import React from "react";

export const settingsDispatchContext = React.createContext();

export function reducer(state, action) {

  let newState = {...state};

  switch (action.type) {

    case "semester":
    case "course": {
      newState[action.type][action.payload.name] = action.payload.value;
      break;
    } 
    
    default:
      throw new Error();
  }

  localStorage.setItem("settings", JSON.stringify(newState));

  return newState;
}
