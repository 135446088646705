import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Course } from "./Course";

import {
  filtersList,
  courses,
  historic_courses,
  passingGrade
} from "./omsa";

export function CourseMasterList({ db, settings, className, style }) {
  let [filter, setFilter] = useState("");
  let [filtersState, setFiltersState] = useState(filtersList);
  let [filtersHistState, setFiltersHistState] = useState("");
  let [filterPassedClasses, setFilterPassedClasses] = useState(true);
  let [sortBy, setsortBy] = useState("");
  let [sortByAscending, setsortByAscending] = useState(true);

  function handleFilterChange(e) {
    setFilter(e.target.value);
  }

  let courses2 = [...courses]
  courses2 = sortBy !== "" ? courses2.sort((a, b) => sortByAscending ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy]) : courses2;
  let filtered = courses2.filter(
    (x) =>
      x.Department.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
      x.FullName.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
      x.Description.toLowerCase().indexOf(filter.toLowerCase()) >= 0 ||
      x.Nick.toLowerCase().indexOf(filter.toLowerCase()) >= 0
  );

  filtersState.forEach((element) => {
    if (element.on) {
      filtered = filtered.filter((x) => element.courses.includes(x.SeqNum));
    }
  });

  if (Object.keys(historic_courses).includes(filtersHistState)) {
    filtered = filtered.filter((x) =>
      historic_courses[filtersHistState].includes(x.SeqNum)
    );
  }

  if (filterPassedClasses) {
    filtered = filtered.filter(
      (x) =>
        !db
          .filter((x) => passingGrade.includes(x.grade) || x.credit || x.audit)
          .map((x) => x.courseSeqNum)
          .includes(x.SeqNum)
    );
  }

  function filterChange(x) {
    filtersState = [...filtersState];
    filtersState.find((filter) => filter.name === x.name).on = !x.on;
    setFiltersState(filtersState);
  }

  function historyChange(e) {
    setFiltersHistState(e.target.value);
  }

  const searchStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: ".5em",
    alignItems: "left"
  };

  return (
    <div className={"couse-master-list "+className}>

<h2>OMSA courses</h2>

      <div style={{ display: "flex", justifyContent: "start" ,  flexDirection: "row", ...style}}>


        <fieldset>
          <legend style={{ textAlign: "start" }}>Filtering:</legend>

          <div style={searchStyle}>
            <input
              placeholder="Search for a course"
              value={filter}
              onChange={handleFilterChange}
            ></input>
            <select onChange={historyChange} style={{ margin: "3px", width: "auto" }}>
              <option>select a past semester</option>
              {Object.keys(historic_courses).map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
            <div onClick={() => setFilterPassedClasses(!filterPassedClasses)} style={{ display: "block", whiteSpace: "nowrap" }} >
              <input
                id="filterPassedCourses"
                type="checkbox"
                checked={filterPassedClasses}
                readOnly
              ></input>
              <label style={{"pointerEvents": "none"}} htmlFor="filterPassedCourses">hide already taken</label>
            </div>
          </div>





          <div className="filters-holder">
            <div className="filters">
              {filtersState.map((x) => (
                <div key={x.name} onClick={() => filterChange(x)}>
                  <input
                    id={x.name}
                    type="checkbox"
                    checked={x.on}
                    readOnly
                  ></input>
                  <label style={{"pointerEvents": "none"}} htmlFor={x.name}>{x.name}</label>
                </div>
              ))}
            </div>
          </div>

        </fieldset>
{/* 
        <fieldset style={{ display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
          <legend style={{ textAlign: "start" }}>Sorting:</legend>
          <label style={{ textAlign: "left" }}>Sort by</label>
          <select onChange={(e) => { setsortBy(e.target.value); }} value={sortBy}>
            <option></option>
            <option>Workload</option>
            <option>Difficulty</option>
            <option>Rating</option>
          </select>
          <label style={{ textAlign: "left" }}>Sort direction</label>
          <select onChange={(e) => { setsortByAscending(e.target.value === "Ascending"); }} value={sortByAscending ? "Ascending" : "Descending"}>
            <option>Ascending</option>
            <option>Descending</option>
          </select>
        </fieldset> */}


      



      </div>

      <Droppable droppableId="MasterList" isDropDisabled={true}>
        {(provided) => (
          <ul style={{"justifyContent": "flex-start"}}  {...provided.droppableProps} ref={provided.innerRef}>
            {filtered.map((course, index) => (
              <Course key={course.SeqNum} course={course} index={index} options={settings.course} />
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </div>
  );
}
