import React, { useState, useContext } from "react";
import { courses } from "./omsa";
import { Semester } from "./Semester";
import { DragDropContext } from "react-beautiful-dnd";

import { settingsDispatchContext } from "./settingsReducer";

export const defaultSettings = {
  course: {
    showFullname: true,
    showNick: false,
    showDescription: true,
    showGrade: false,
    showOMSHub: false,
  },
  semester: {
    showGPA: false,
    showCreditHours: false,
    showWorkHours: true,
  },
};

//TOD add settings here, need them to be global, but this is in a different DOM tree from app/providers

export function Settings({ settings }) {
  const [showFullname, setshowFullname] = useState(
    settings.course.showFullname
  );
  const [showNick, setshowNick] = useState(settings.course.showNick);
  const [showDescription, setshowDescription] = useState(
    settings.course.showDescription
  );
  const [showGrade, setshowGrade] = useState(settings.course.showGrade);
  const [showOMSHub, setshowOMSHub] = useState(
    settings.course.showOMSHub
  );
  const [showDifficulty, setshowDifficulty] = useState(
    settings.course.showDifficulty
  );
  const [showWorkload, setshowWorkload] = useState(
    settings.course.showWorkload
  );
  const [showRating, setshowRating] = useState(settings.course.showRating);

  const [showGPA, setshowGPA] = useState(settings.semester.showGPA);
  const [showCreditHours, setShowCreditHours] = useState(
    settings.semester.showCreditHours
  );
  const [showWorkHours, setshowWorkHours] = useState(
    settings.semester.showWorkHours
  );

  const dispatch = useContext(settingsDispatchContext);

  const course = { ...courses.find((x) => x.SeqNum === 2) };
  course.grade = "A";

  const options = {
    course: {
      showOMSHub,
      showNick,
      showFullname,
      showDescription,
      showGrade,
      showDifficulty,
      showWorkload,
      showRating,
    },
    semester: {
      showGPA,
      showCreditHours,
      showWorkHours,
    },
  };

  const updateOptions = [
    {
      name: "showFullname",
      get: showFullname,
      set: setshowFullname,
      label: "Show full name",
      type: "course"
    },
    {
      name: "showNick",
      get: showNick,
      set: setshowNick,
      label: "Show nick name",
      type: "course"
    },
    {
      name: "showDescription",
      get: showDescription,
      set: setshowDescription,
      label: "Show description",
      type: "course"
    },
    {
      name: "showGrade",
      get: showGrade,
      set: setshowGrade,
      label: "Show grade",
      type: "course"
    },
    // {
    //   name: "showOMShub",
    //   get: showOMSHub,
    //   set: setshowOMSHub,
    //   label: "Show OMSHub link",
    //   type: "course"
    // },
    // {
    //   name: "showDifficulty",
    //   get: showDifficulty,
    //   set: setshowDifficulty,
    //   label: "Show difficulty",
    //   type: "course"
    // },
    // {
    //   name: "showWorkload",
    //   get: showWorkload,
    //   set: setshowWorkload,
    //   label: "Show workload",
    //   type: "course"
    // },
    // {
    //   name: "showRating",
    //   get: showRating,
    //   set: setshowRating,
    //   label: "Show rating",
    //   type: "course"
    // },
    { name: "showGPA",
     get: showGPA,
      set: setshowGPA,
       label: "Show GPA" ,
       type: "semester"
      },
    {
      name: "showCreditHours",
      get: showCreditHours,
      set: setShowCreditHours,
      label: "Show credit hours",
      type: "semester"
    },
    // {
    //   name: "showWorkHours",
    //   get: showWorkHours,
    //   set: setshowWorkHours,
    //   label: "Show work hours",
    //   type: "semester"
    // },
  ];

  const updateCourseSetting = function (property) {
    const payload = {
      name: property.name,
      value: !property.get,
    };
    dispatch({ type: property.type, payload });
    property.set(!property.get);
  };

  const semesterObject = {
    semester: "Fall",
    classes: [
      {
        SeqNum: 7005,
        Department: "CSE",
        Number: "6040",
        FullName: "CSE 6040",
        Description: "Computing for Data Analysis - Methods and Tools",
        Nick: "iCDA",
        CR: 3,
        Difficulty: 3.22,
        Workload: 9.69,
        Rating: 4.43,
        Prerequisites: [],
        PrerequisitesType: "soft",
        grade: "A",
        courseSeqNum: 1,
      },
      {
        SeqNum: 7006,
        Department: "ISYE",
        Number: "6501",
        FullName: "ISYE 6501",
        Description: "Introduction to Analytics Modeling",
        Nick: "iAM",
        CR: 3,
        Difficulty: 2.87,
        Workload: 10.03,
        Rating: 4.25,
        Prerequisites: [],
        PrerequisitesType: "soft",
        grade: "A",
        courseSeqNum: 2,
      },
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        textAlign: "left",
        margin: "2rem",
        alignItems: "center",
      }}
    >
      <ul>
        {updateOptions.map((property) => (
          <li>
            <div onClick={() => updateCourseSetting(property)}>
              <input type="checkbox" checked={property.get} />
              <label>{property.label}</label>
            </div>
          </li>
        ))}
      </ul>

      <ul style={{ marginLeft: "2rem", alignSelf: "start" }}>
        <li className="year semester-container" style={{"width":"75%"}}>
          <DragDropContext onDragEnd={() => {}} onDragUpdate={() => {}}>
            <Semester
              key="optionsSemester"
              semester={semesterObject}
              year={2022}
              options={options}
            />
          </DragDropContext>
        </li>

        {/* 
                <li className={"course " + course.Department}>
                    <CourseCard course={course} options={options}>   </CourseCard>
                </li> */}
      </ul>
    </div>
  );
}
