import React from "react";
import { getNextSeq, updateGrade } from "./databaseToJsonConverter";

export const scheduleDispatchContext = React.createContext();

export function reducer(state, action) {
  let db = [...state];

  switch (action.type) {
    case "updateGrade": {
      let { SeqNum, grade } = action.payload;
      db = updateGrade(db, SeqNum, grade);
      break;
    }
    case "add": {
      let {
        courseSeqNum,
        year,
        semester,
        grade,
        credit,
        audit
      } = action.payload;

      db.push({
        year: year,
        semester: semester,
        SeqNum: getNextSeq(db),
        courseSeqNum: courseSeqNum,
        grade: grade,
        credit,
        audit
      });
      break;
    }
    case "move": {
      let { SeqNum, newYear, newSemester } = action.payload;

      let record = db.find((x) => x.SeqNum === SeqNum);
      record.year = newYear;
      record.semester = newSemester;
      break;
    }
    case "delete": {
      let { SeqNum } = action.payload;

      let record = db.find((x) => x.SeqNum === SeqNum);
      const index = db.indexOf(record);
      //TODO does this handle small arrays?
      db = [...db.slice(0, index), ...db.slice(index + 1)];
      break;
    }

    case "deleteAll" :{
      db = [];
      break;
    }
    case "import": {
      db = action.payload;
      break;
    }

    default:
      throw new Error();
  }
  localStorage.setItem("db", JSON.stringify(db));
  return db;
}
