import React from "react";

export function OutsideCoursePicker({ track, onChangeTrack, title, tracks }) {
  return (
    <div className="tracks-container">
      <div>
        <div className="title">{title}</div>
        <ul className="tracks">
          {tracks.map((x) => (
            <li key={x.value}>
              <input
                type="checkbox"
                id={x.value}
                value={x.value}
                checked={track.indexOf(x.value) >= 0}
                onChange={onChangeTrack}
              ></input>
              <label>{x.name}</label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
