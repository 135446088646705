import React from "react";
import lz from "lz-string";

export function Exporter({db, cancel}) {

  function convertKeys (dbrow) {
    const converter = {
      "audit":"a",
      "courseSeqNum":"csn",
      "credit":"c",
      "grade":"g",
      "semester":"s",
      "SeqNum":"n",
      "year":"y"
    }

    const season = {
      "Fall" : "F",
      "Spring" : "Sp",
      "Summer" : "Su",
    }

    const converterValue = {
      "audit": (value) => value ? 1 : 0,
      "courseSeqNum": (value) => value ,
      "credit":(value) => value ? 1 : 0,
      "grade":(value) => value ,
      "semester":(value) => season[value],
      "SeqNum":(value) => value - 7000,
      "year":(value) => value ==null ? null :  value - 2016
    }

    let smallRow = {...dbrow}

    Object.keys(smallRow).forEach(key => {
      smallRow[key]= converterValue[key](smallRow[key]);
    });

    smallRow = Object.keys(smallRow).reduce( (destination, current) => {destination[converter[current]] = smallRow[current]; return destination; }, {})
    return smallRow;   
  }


  const destination = []
  db.forEach(dbRow => {
    destination.push(convertKeys(dbRow)) 
  });

  const compressed = lz.compressToEncodedURIComponent(JSON.stringify(destination))

  return (
    <div style={{display:"flex", flexDirection:"column", alignItems: "center", padding: "1rem"}} >
        <div>Copy the exported degree plan string for safe keeping (in a text file, email, the cloud, etc) to use when importing</div>
        
        <textarea style={{margin:".5rem"}} id="exportData"  rows="8" cols="100" value={compressed}></textarea>

        <div style={{display:"flex", flexDirection:"row",  justifyContent: "flex-end",  width: "100%"}}>
          <button style={{margin: ".5rem"}} onClick={cancel}>OK</button>
        </div>
    </div>




  );
}
