import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { Course } from "./Course";
import { calcGPA } from "./utilities";

export function Semester({ semester, year, options }) {
  var label = semester.semester;
  var classes = semester.classes;
  var uniqueid = label + ":" + year;

  // todo add more grades, move to utility function
  let gpa;

  if (options.semester.showGPA) {
    let grade2gpa = { A: 4, B: 3, C: 2, D: 1, F: 0 };
    let grades = classes.map((x) => grade2gpa[x.grade]);
    gpa =
      grades.length !== 0
        ? parseFloat(grades.reduce((a, c) => a + c) / grades.length).toFixed(2)
        : "";

    gpa = calcGPA(semester.classes);
  }

  let creditHours = "";
  if (options.semester.showCreditHours) {
    creditHours = classes.map((x) => x.CR).reduce((a, b) => a + b, 0);
    creditHours = creditHours > 0 ? creditHours + " CR" : "";
  }

  let workHours = creditHours !== "" ? "" : "" ;
  if (false && options.semester.showWorkHours) {
    let workloadHours = classes
      .map((x) => x.Workload)
      .reduce((a, b) => a + b, 0);
    workHours += workloadHours !== 0 ? workloadHours.toFixed(1) + " HR" : "";
  }

  return (
    <div className={label !== "Summer" ? "semester" : "semester summer"}>
      <div className="semester-header">
        <div className="semesterLabel">
          {label + ((creditHours || workHours) ? ` (${creditHours}${workHours})` : "")}
        </div>
        {gpa && <div className="semesterGPA">{"GPA:" + gpa}</div>}
      </div>

      <Droppable droppableId={uniqueid}>
        {(provided, snapshot) => {
          let droppableStyleClass = snapshot.isDraggingOver
            ? "drag-hovered"
            : "";
          return (
            <ul
              className={"semester-course-list " + droppableStyleClass}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {classes.map((course, index) => (
                <Course
                  key={course.SeqNum}
                  course={course}
                  index={index}
                  options={options.course}
                />
              ))}
              {provided.placeholder}
            </ul>
          );
        }}
      </Droppable>
    </div>
  );
}
