import React from "react";
import {passingGrade } from "./omsa";

export function Prereq({ schedule, masterCourseList }) {

//TODO turn this into a set, to prevent duplicates, then turn to an array
var prereqHashTable = []

schedule.forEach(course => {
    masterCourseList.forEach(mastercourse => {
        if(course.courseSeqNum === mastercourse.SeqNum){
            if(mastercourse.Prerequisites.length > 0){
                //prereqHashTable[course.courseSeqNum] = mastercourse.Prerequisites;
                mastercourse.Prerequisites.forEach(prereq => prereqHashTable.push({courseID:course.courseSeqNum, prereq:prereq, isHard:mastercourse.PrerequisitesType === "hard" }))
            }
        }
    });
});
function hasCoursePrereq(schedule,anchorCourseID, PreReqcourseID){

    const prereqCourse = schedule.filter(x => x.courseSeqNum === PreReqcourseID && (passingGrade.includes(x.grade) || x.credit || x.audit));
    const anchorCourse = schedule.filter(x => x.courseSeqNum === anchorCourseID);

    let hasCoursePrereq = false;
    if(prereqCourse.length > 0){
        hasCoursePrereq = compareCoursetime(prereqCourse[0], anchorCourse[0])
    }
 

    return hasCoursePrereq;

}

function getCourseObjectbyID(courseID){
    return masterCourseList.find(x => x.SeqNum === courseID);
}

// check if course 1 is less than course 2
function compareCoursetime(course1, course2){
    if(isNaN(course1.year)){
        return true;
    }

    if(course1.year  === course2.year){
        if (course1.semester === course2.semester || 
            course1.semester === "Fall" || 
            (course1.semester === "Summer" && course2.semester ==="Spring")){
            return false;
        } else {
            return true;
        }
    } else {
        return course1.year  < course2.year;
    }

}


    //const hasZeroWarnings = prereqHashTable.filter(x => hasCoursePrereq(schedule, x.courseID, x.prereq)) === 0;



    const hasZeroWarnings = prereqHashTable.every(courseObj =>  
        {
            const hasCoursePrereqbool = hasCoursePrereq(schedule, courseObj.courseID, courseObj.prereq);
            if (hasCoursePrereqbool){
                return true;
            } else {

                return false;
            }
               
        });

  return (
      <div >
            <h3 style={{"textAlign": "start", "fontWeight" : "unset", "margin":".1em", marginLeft:"1em"}}> 
            {`${hasZeroWarnings ? "✅":"❌"}Prerequisites (soft and hard) `}  
            </h3>
            {hasZeroWarnings ? null :  <ul style={{marginLeft:"2em"}} >
                {prereqHashTable.map(courseObj =>  
                {
                    const hasCoursePrereqbool = hasCoursePrereq(schedule, courseObj.courseID, courseObj.prereq);
                        return (hasCoursePrereqbool ? null : <>
                            <li style={{"whiteSpace": "nowrap"}}>
                                {`${getCourseObjectbyID(parseInt(courseObj.courseID)).FullName}  ${courseObj.isHard? "requires":"recommends"}
                                ${getCourseObjectbyID(parseInt(courseObj.prereq)).FullName}: 
                                ${hasCoursePrereq(schedule, courseObj.courseID, courseObj.prereq) ? "✅" : "❌"}` } 
                            </li>
                        </>)
                }
               
                )}
            </ul>}
    </div>
  );
}
