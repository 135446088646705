import React, {useContext} from "react";
import { Draggable } from "react-beautiful-dnd";
import { CourseCard } from "./CourseCard";
import { scheduleDispatchContext } from "./scheduleReducer";


export function Course({ course, index , options}) {
  const dispatch = useContext(scheduleDispatchContext);


  // SeqNum: 28,
  // Department: "MGT",
  // Number: "6748",
  // FullName: "MGT 6748",
  // Description: "Applied Analytics Practicum - Business Track",
  // Nick: "Pract",
  // CR: 6


const changeGrade = function (newGrade){
  dispatch({
    type: "updateGrade",
    payload: { SeqNum: course.SeqNum, grade: newGrade }
  });
}


  return (
    <Draggable
      key={course.class}
      draggableId={course.SeqNum.toString()}
      index={index}
    >
      {(provided) => (
        <li
          className={"course " +  course.Department}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
         <CourseCard course = {course} changeGrade={changeGrade} options={options}></CourseCard>
        </li>
      )}
    </Draggable>
  );
}
