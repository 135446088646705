
export const coreSeqNum = [1, 2, 3];
export const advancedCoreSeqNum = [4, 5];
export const statsSeqNum = [13, 8, 9, 10, 14, 15];
export const operationsResearch = [11, 12, 33];
export const Practicum = [26, 27, 28];
export const CTrackSeqNum = [7, 6, 13, 19, 20, 21, 22, 23, 24, 29, 30, 31, 32];
export const BTrackSeqNum = [16, 17, 18, 25, 32, 34, 35, 36];
export const ATrackSeqNum = [8, 13, 14, 12, 11, 10, 9, 15, 29];

export const passingGrade = ["A", "B", "C", "D"];

export var courses = [
  {
    SeqNum: 1,
    Department: "CSE",
    Number: "6040",
    FullName: "CSE 6040",
    Description: "Computing for Data Analysis - Methods and Tools",
    Nick: "iCDA",
    CR: 3,
    Difficulty: 3.22,
    Workload: 9.69,
    Rating: 4.43,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 2,
    Department: "ISYE",
    Number: "6501",
    FullName: "ISYE 6501",
    Description: "Introduction to Analytics Modeling",
    Nick: "iAM",
    CR: 3,
    Difficulty: 2.87,
    Workload: 10.03,
    Rating: 4.25,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 3,
    Department: "MGT",
    Number: "8803",
    FullName: "MGT 8803",
    Description: "(MGT 6754) Business Fundamentals for Analytics",
    Nick: "BFA",
    CR: 3,
    Difficulty: 2.94,
    Workload: 7.52,
    Rating: 2.41,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },

  {
    SeqNum: 4,
    Department: "CSE",
    Number: "6242",
    FullName: "CSE 6242",
    Description: "Data and Visual Analytics",
    Nick: "DVA",
    CR: 3,
    Difficulty: 3.24,
    Workload: 15.53,
    Rating: 3,
    Prerequisites: [1],
    PrerequisitesType : "soft"
  },

  {
    SeqNum: 5,
    Department: "MGT",
    Number: "6203",
    FullName: "MGT 6203",
    Description: "Data Analytics in Business",
    Nick: "DAB",
    CR: 3,
    Difficulty: 1.91,
    Workload: 4.47,
    Rating: 2.25,
    Prerequisites: [2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 6,
    Department: "CS ",
    Number: "6400",
    FullName: "CS 6400",
    Description: "Database Systems - Concepts and Design",
    Nick: "DBS",
    CR: 3,
    Difficulty: 2.76,
    Workload: 10.69,
    Rating: 2.69,
    Prerequisites: [1],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 7,
    Department: "CSE",
    Number: "6250",
    FullName: "CSE 6250",
    Description: "Big Data Analytics for Healthcare",
    Nick: "BD4H",
    CR: 3,
    Difficulty: 4.41,
    Workload: 29.54,
    Rating: 3.81,
    Prerequisites: [4],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 8,
    Department: "ISYE",
    Number: "6402",
    FullName: "ISYE 6402",
    Description: "Time Series Analysis",
    Nick: "TSA",
    CR: 3,
    Difficulty: 4.24,
    Workload: 12.64,
    Rating: 1.84,
    Prerequisites: [9],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 9,
    Department: "ISYE",
    Number: "6414",
    FullName: "ISYE 6414",
    Description: "Statistical Modeling and Regression Analysis",
    Nick: "REG",
    CR: 3,
    Difficulty: 2.97,
    Workload: 9.01,
    Rating: 3.34,
    Prerequisites: [2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 10,
    Department: "ISYE",
    Number: "6420",
    FullName: "ISYE 6420",
    Description: "Theory and Practice of Bayesian Statistics",
    Nick: "Bayes",
    CR: 3,
    Difficulty: 3.06,
    Workload: 11.73,
    Rating: 2.65,
    Prerequisites: [2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 11,
    Department: "ISYE",
    Number: "6644",
    FullName: "ISYE 6644",
    Description: "Simulation and Modeling",
    Nick: "SIM",
    CR: 3,
    Difficulty: 3.24,
    Workload: 9.69,
    Rating: 4.42,
    Prerequisites: [2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 12,
    Department: "ISYE",
    Number: "6669",
    FullName: "ISYE 6669",
    Description: "Deterministic Optimization",
    Nick: "DO",
    CR: 3,
    Difficulty: 3.79,
    Workload: 10.45,
    Rating: 4.03,
    Prerequisites: [2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 13,
    Department: "ISYE",
    Number: "6740",
    FullName: "ISYE 6740",
    Description: "ML1 - Computational Data Analytics",
    Nick: "CDA",
    CR: 3,
    Difficulty: 3.68,
    Workload: 14,
    Rating: 4,
    Prerequisites: [1,2],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 14,
    Department: "ISYE",
    Number: "7406",
    FullName: "ISYE 7406",
    Description: "Data Mining and Statistical Learning",
    Nick: "DMSL",
    CR: 3,
    Difficulty: 2.5,
    Workload: 13.33,
    Rating: 4,
    Prerequisites: [9],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 15,
    Department: "ISYE",
    Number: "8803",
    FullName: "ISYE 8803",
    Description: "ML2 - High-Dimensional Data Analytics",
    Nick: "HDDA",
    CR: 3,
    Difficulty: 4.31,
    Workload: 18.86,
    Rating: 4.21,
    Prerequisites: [2,13],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 16,
    Department: "MGT",
    Number: "6311",
    FullName: "MGT 6311",
    Description: "Digital Marketing",
    Nick: "DM",
    CR: 3,
    Difficulty: 1.38,
    Workload: 3.63,
    Rating: 4.25,
    Prerequisites: [3],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 17,
    Department: "MGT",
    Number: "8813",
    FullName: "MGT 8813",
    Description: "Financial Modeling",
    Nick: "FM",
    CR: 3,
    Difficulty: 1.29,
    Workload: 4.64,
    Rating: 3,
    Prerequisites: [3],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 18,
    Department: "MGT",
    Number: "8823",
    FullName: "MGT 8823",
    Description: "Data Analysis for Continuous Improvement",
    Nick: "DACI",
    CR: 3,
    Difficulty: 1.09,
    Workload: 3.64,
    Rating: 3.45,
    Prerequisites: [3],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 19,
    Department: "CS ",
    Number: "6601",
    FullName: "CS 6601",
    Description: "Artificial Intelligence",
    Nick: "AI",
    CR: 3,
    Difficulty: 4.11,
    Workload: 22.95,
    Rating: 4.2,
    Prerequisites: [1],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 20,
    Department: "CS ",
    Number: "6750",
    FullName: "CS 6750",
    Description: "Human-Computer Interaction",
    Nick: "HCI",
    CR: 3,
    Difficulty: 2.56,
    Workload: 11.95,
    Rating: 4.13,
    Prerequisites: [1],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 21,
    Department: "CS ",
    Number: "7637",
    FullName: "CS 7637",
    Description: "Knowledge-Based Artificial Intelligence",
    Nick: "KBAI",
    CR: 3,
    Difficulty: 3.1,
    Workload: 13.28,
    Rating: 3.62,
    Prerequisites: [19],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 22,
    Department: "CS ",
    Number: "7642",
    FullName: "CS 7642",
    Description: "Reinforcement Learning and Decision Making",
    Nick: "RL",
    CR: 3,
    Difficulty: 4.11,
    Workload: 21.98,
    Rating: 3.9,
    Prerequisites: [13],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 23,
    Department: "CS ",
    Number: "7643",
    FullName: "CS 7643",
    Description: "Deep Learning",
    Nick: "DL",
    CR: 3,
    Difficulty: 3.91,
    Workload: 17.91,
    Rating: 4.51,
    Prerequisites: [13,12],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 24,
    Department: "CS ",
    Number: "7646",
    FullName: "CS 7646",
    Description: "Machine Learning for Trading",
    Nick: "ML4T",
    CR: 3,
    Difficulty: 2.49,
    Workload: 10.65,
    Rating: 4.12,
    Prerequisites: [1],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 25,
    Department: "MGT",
    Number: "6727",
    FullName: "MGT 6727",
    Description: "Privacy for Professionals",
    Nick: "P4P",
    CR: 3,
    Difficulty: 1.86,
    Workload: 3.57,
    Rating: 4.43,
    Prerequisites: [3],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 26,
    Department: "CSE",
    Number: "6748",
    FullName: "CSE 6748",
    Description: "Applied Analytics Practicum - Computing Track",
    Nick: "Pract",
    CR: 6,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [4, 5],
    PrerequisitesType : "hard"
  },
  {
    SeqNum: 27,
    Department: "ISYE",
    Number: "6748",
    FullName: "ISYE 6748",
    Description: "Applied Analytics Practicum - Analytics Track",
    Nick: "Pract",
    CR: 6,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [4, 5],
    PrerequisitesType : "hard"
  },
  {
    SeqNum: 28,
    Department: "MGT",
    Number: "6748",
    FullName: "MGT 6748",
    Description: "Applied Analytics Practicum - Business Track",
    Nick: "Pract",
    CR: 6,
    Difficulty: 2.5,
    Workload: 18,
    Rating: 4,
    Prerequisites: [4, 5],
    PrerequisitesType : "hard"
  },
  {
    SeqNum: 29,
    Department: "CS",
    Number: "7280",
    FullName: "CS 7280",
    Description: "Network Science",
    Nick: "NS",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 30,
    Department: "CSE",
    Number: "6742",
    FullName: "CSE 6742",
    Description: "Modeling, Simulation & Military Gaming",
    Nick: "MSMG",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 31,
    Department: "CSE",
    Number: "8803 ",
    FullName: "CSE 8803",
    Description: "Applied Natural Language Processing",
    Nick: "ANLP",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 32,
    Department: "MGT",
    Number: "8833",
    FullName: "MGT 8833",
    Description: "Analysis of Unstructured Data",
    Nick: "AUD",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 33,
    Department: "ISYE",
    Number: "6650",
    FullName: "ISYE 6650",
    Description: "Probabilistic Models",
    Nick: "PM",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  },
  {
    SeqNum: 34,
    Department: "PUBP",
    Number: "6725",
    FullName: "PUBP 6725",
    Description: "Information Security Policies",
    Nick: "ISP",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  }
  ,
  {
    SeqNum: 35,
    Department: "MGT",
    Number: "6059",
    FullName: "MGT 6059",
    Description: "Emerging Technologies",
    Nick: "ET",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  }
  ,
  {
    SeqNum: 36,
    Department: "MGT",
    Number: "6655 ",
    FullName: "MGT 6655 ",
    Description: "Business Data Preparation & Visualization",
    Nick: "BDPV",
    CR: 3,
    Difficulty: NaN,
    Workload: NaN,
    Rating: NaN,
    Prerequisites: [],
    PrerequisitesType : "soft"
  }


];

/*eslint-disable */

export const historic_courses = {


 "2024 Fall":[6,19,20,29,21,22,23,24,1,4,7,30,26,31,8,9,10,2,11,33,12,13,27,14,15,35,5,16,36,25,28,3,17,18,32,34],
 "2024 Summer": [6,20,29,23,24,26,31,9,2,11,13,27,15,5,16,25,28,3,17,18,32],
 "2024 Spring": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,33,36],

 "2023 Fall": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,26,27,28,29,30,31, 32, 33, 34, 35],
  "2023 Summer": [2,3,5,6,9,11,13,15,16,17,20,23,24,25,26,27,28,29,31],
  "2023 Spring": [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,19,20,21,22,23,24,25,26,27,28,29,30,31],

  "2022 Fall": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 23, 24, 26, 27, 28],
  "2022 Summer": [2, 3, 5, 6, 9, 11, 13, 15, 16, 17, 18, 20, 22, 23, 24, 25, 26, 27, 28],
  "2022 Spring": [2,3,4,5,6,8,9,10,11,12,13,14,15,16,17,18,19,22,23,24,25,26,27,28],

  "2021 Fall": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 23, 24, 26, 27, 28],
  "2021 Summer": [1, 2, 3, 5, 6, 9, 11, 13, 15, 16, 17, 18, 20, 22, 23, 24, 25, 26, 27, 28],
  "2021 Spring": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 22, 23, 24, 25, 26, 27, 28],


  "2020 Fall": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 21, 24, 26, 27, 28],
  "2020 Summer": [2, 3, 5, 6, 9, 11, 13, 15, 16, 17, 18, 20, 25, 26, 27, 28],
  "2020 Spring ": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 22, 25, 26, 27, 28],

  "2019 Fall": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 21, 24, 26, 27, 28],
  "2019 Summer": [2, 3, 6, 9, 11, 15, 16, 17, 18, 26, 27, 28],
  "2019 Spring ": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 16, 17, 18, 26, 27, 28],

  /*
  "2018 Fall" : [],
} , 
  "2018 Summer" : [],
} , 
  "2018 Spring " :[],
} , 

  "2017 Fall" : [],
} , 
  "2017 Summer" : [],
} , 
  "2017 Spring " :[],
} , 
  */


};

const summerSet = new Set();
Object.keys(historic_courses).
  filter(x => x.includes("Summer")).
    forEach(x => historic_courses[x].
      forEach(summerSet.add, summerSet))

export const summerSeqNum = Array.from(summerSet).filter(x => x !==1);

/*eslint-enable */
export const filtersList = [
  { name: "Core ", courses: coreSeqNum, on: false },
  { name: "Advance core ", courses: advancedCoreSeqNum, on: false },
  { name: "Stats ", courses: statsSeqNum, on: false },
  { name: "Operations research ", courses: operationsResearch, on: false },
  { name: "A Track ", courses: ATrackSeqNum, on: false },
  { name: "B Track ", courses: BTrackSeqNum, on: false },
  { name: "C Track ", courses: CTrackSeqNum, on: false },
  { name: "Practicum ", courses: Practicum, on: false },
  { name: "Summer", courses: summerSeqNum, on:false},


];
