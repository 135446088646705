import { courses } from "./omsa";

export function getNextSeq(db) {
  const lastSeq = db.length > 0 ? Math.max(...db.map((x) => x.SeqNum)) : 7000;
  return lastSeq + 1;
}

export function updateGrade(db, SeqNum, grade) {
  let record = db.find((x) => x.SeqNum === SeqNum);
  record.grade = grade;
  return db;
}

export function getDB() {
  const databaseArray = [];
  return databaseArray;
}

function getCourseByAcademicYear(db, startYear) {
  return db.filter(
    (x) =>
      (parseInt(x.year, 10) === startYear && x.semester === "Fall") ||
      (parseInt(x.year, 10) === startYear + 1 &&
        (x.semester === "Spring" || x.semester === "Summer"))
  );
}

function getCourseData(x) {
  return {
    ...courses.find((course) => course.SeqNum === x.courseSeqNum),
    grade: x.grade,
    SeqNum: x.SeqNum,
    courseSeqNum: x.courseSeqNum
  };
}

export function convertToJSON(databaseArray, yearView) {
  // setup schedule
  let schedule = [];
  let schedule2 = [];

  // capture the unique years
  let years = [
    ...new Set(
      databaseArray
        .map((x) => {
          return parseInt(x.year, 10);
        })
        .filter((x) => x)
    )
  ];

  if (years.length === 0) {
    years.push(new Date().getFullYear());
  }

  let minOffset = 0;
  let maxOffset = 0;

  if (yearView === "Academic") {
    const minYear = Math.min(...years);
    let semesters = databaseArray
      .filter((x) => x.year === minYear.toString())
      .map((x) => x.semester);
    if (semesters.includes("Spring") || semesters.includes("Summer")) {
      minOffset = 1;
    }

    const maxYear = Math.max(...years);
    semesters = databaseArray
      .filter((x) => x.year === maxYear.toString())
      .map((x) => x.semester);
    if (semesters.includes("Spring") || semesters.includes("Summer")) {
      maxOffset = 1;
    }
  }

  const minYear = Math.min(...years) - 1 - minOffset;
  const maxYear = Math.max(...years) + 1 - maxOffset;

  years = Array.from(new Array(maxYear - minYear + 1), (x, i) =>
    (i + minYear).toString()
  );

  if (yearView === "Academic") {
    years.forEach((year) => {
      let currentYearClasses = getCourseByAcademicYear(
        databaseArray,
        parseInt(year, 10)
      );

      schedule2.push({
        year: `${year} - ${parseInt(year, 10) + 1}`,
        semesters: [
          {
            semester: "Fall",
            classes: currentYearClasses
              .filter((x) => x.semester === "Fall")
              .map(getCourseData)
          },
          {
            semester: "Spring",
            classes: currentYearClasses
              .filter((x) => x.semester === "Spring")
              .map(getCourseData)
          },
          {
            semester: "Summer",
            classes: currentYearClasses
              .filter((x) => x.semester === "Summer")
              .map(getCourseData)
          }
        ]
      });
    });

    return schedule2;
  }

  // add years to the schedule, and placeholder for semesters
  years.forEach((x) =>
    schedule.push({
      year: x,
      semesters: [
        { semester: "Spring", classes: [] },
        { semester: "Summer", classes: [] },
        { semester: "Fall", classes: [] }
      ]
    })
  );

  // add the semesters to the semeser array
  // years.forEach((year) => {
  //   const semestersInThisYear = [
  //     ...new Set(
  //       databaseArray.filter((x) => x.year === year).map((x) => x.semester)
  //     )
  //   ];
  //   semestersInThisYear.forEach((semesterInYear) => {
  //     schedule
  //       .find((x) => x.year === year)
  //       .semesters.push({ semester: semesterInYear, classes: [] });
  //   });
  // });

  // add the classes to the schedule object
  schedule.forEach((year) => {
    year.semesters.forEach((semester) => {
      let classes = databaseArray
        .filter((x) => x.year === year.year && x.semester === semester.semester)
        .map((x) => {
          return {
            ...courses.find((course) => course.SeqNum === x.courseSeqNum),
            grade: x.grade,
            SeqNum: x.SeqNum,
            courseSeqNum: x.courseSeqNum
          };
        });
      if (classes) {
        semester.classes.push(...classes);
      }
    });
  });

  return schedule;
}

export function convertToDB(schedule) {
  let db = [];
  schedule.forEach((year) => {
    year.semesters.forEach((semester) => {
      semester.classes.forEach((course) => {
        db.push({
          year: year.year,
          semester: semester.semester,
          SeqNum: course.SeqNum,
          grade: course.grade,
          courseSeqNum: course.courseSeqNum
        });
      });
    });
  });
  return db;
}
