import { courses } from "./omsa";

export const grades = ["A", "B", "C", "D", "F", "W"];

export function calcGPA(db, grade2gpa) {
  // todo add more grades, move to utility function
  grade2gpa = { A: 4, B: 3, C: 2, D: 1, F: 0, W: 0 };

  const courseAndGrade = db2FullCourse(db);
  const gpaWeight = courseAndGrade
    .map((x) => grade2gpa[x.grade] * x.CR)
    .reduce((a, b) => a + b, 0);
  const totalCR = courseAndGrade
    .filter((x) => !(x.grade === "W"))
    .map((x) => x.CR)
    .reduce((a, b) => a + b, 0);

  let gpa = totalCR !== 0 ? parseFloat(gpaWeight / totalCR).toFixed(2) : "";
  return gpa;
}

function db2FullCourse(db) {
  const courseAndGrade = db
    .filter((x) => !(x.credit || x.audit))
    .map((x) => {
      return {
        ...courses.find((y) => y.SeqNum === x.courseSeqNum),
        grade: x.grade
      };
    });

  return courseAndGrade;
}

export const tracks = [
  { name: "Analytical Tools Track", value: "A" },
  { name: "Business Track", value: "B" },
  { name: "Computational Track", value: "C" }
];

export const edx = [
  { name: "CSE 6040", value: 1 },
  { name: "ISYE 6501", value: 2 },
  { name: "MGT 6203", value: 5 }
];

export const audit = [
  { name: "CSE 6040", value: 1 },
  { name: "ISYE 6501", value: 2 },
  { name: "MGT 8803", value: 3 }
];

export const semester2year = [
  { name: "Fall", value: 0 },
  { name: "Spring", value: 1 },
  { name: "Summer", value: 1 }
];
